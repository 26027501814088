<template>
    <div :class="[page ? 'page_' + page.id : '']">
        <CovidAlert />
        <ParkingFinder v-if="page && page.appearance.layout === 'layout-1'" :page="page"/>

        <div v-for="(content, index) in contents" :key="content.id">
            <section
                :id="'c' + content.id"
                class="mx-auto"
                :class="[content.appearance.frameClass == 'default' ? 'max-w-7xl px-4 sm:px-6 lg:px-16' : '']"
            >
                <Space :space="content.appearance.spaceBefore" v-if="!(index == 0 && content.type == 'menu_pages')" />

                <div
                    :class="[
                        content.appearance.frameClass == 'default' ? 'rounded-lg' : '',
                        content.appearance.backgroundColor != '' ? 'bg-sw-' + content.appearance.backgroundColor : '',
                        content.appearance.backgroundPadding == '1' ? ' p-4 sm:p-8 lg:p-10' : '',
                        content.appearance.textColor == 'white' ? ' text-' + content.appearance.textColor : '',
                    ]"
                >
                    <div :class="[content.appearance.frameClass == 'none-2' ? 'mx-auto max-w-7xl px-4 sm:px-6 lg:px-16' : '']">
                        <component
                            v-if="content.type != 'pwa_integration' && content.type != 'shortcut'"
                            :is="content.type"
                            :content="content.content"
                            :nested_contents="nested_contents ? nested_contents[content.id] : null"
                            :appearance="content.appearance"
                            :key="content.id"
                            :page="page"
                        />
                        <div v-else-if="content.type == 'shortcut'">
                            <div
                                v-for="shortcut in content.content['shortcut']"
                                :key="shortcut.id"
                                :class="[
                                    shortcut.appearance.frameClass == 'default' ? 'rounded-lg' : '',
                                    shortcut.appearance.backgroundColor != '' ? 'bg-sw-' + shortcut.appearance.backgroundColor : '',
                                    shortcut.appearance.backgroundPadding == '1' ? ' p-4 sm:p-8 lg:p-10' : '',
                                    shortcut.appearance.textColor == 'white' ? ' text-' + shortcut.appearance.textColor : '',
                                ]"
                            >
                                <component :is="shortcut.type" :content="shortcut.content" :appearance="shortcut.appearance" :page="page" />
                            </div>
                        </div>

                        <div class="space-y-4" v-else-if="content.type == 'pwa_integration'">
                            <div>
                                <Headers :content="content.content" />
                                <Bodytext :content="content.content" />
                            </div>
                            <component
                                v-if="content.type == 'pwa_integration'"
                                :is="content.content.integrations"
                                :key="content.id"
                                :filters="content.content.flexform"
                                :appearance="content.content.flexform"
                                :page="page"
                            />
                            <Link
                                class="w-full"
                                linkClass="w-full"
                                :style="content.content.linkStyle"
                                :text="content.content.linkText"
                                :link="content.content.headerLink"
                                v-if="content.content.headerLink && content.content.linkText"
                            />
                        </div>
                    </div>
                </div>

                <Space :space="content.appearance.spaceAfter" />
            </section>
        </div>
    </div>
</template>

<script>
import Html from '@/components/contents/Html'
import Image from '@/components/contents/Image'
import Table from '@/components/contents/Table'
import Text from '@/components/contents/Text'
import Textpic from '@/components/contents/Textpic'
import Textpicbox from '@/components/contents/Textpicbox'
import Textpicslider from '@/components/contents/Textpicslider'
import Calltoaction from '@/components/contents/Calltoaction'
import Menu_pages from '@/components/contents/MenuPages'
import Menu_subpages from '@/components/contents/MenuSubpages'
import Space from '@/components/contents/Space'
import Div from '@/components/contents/Div'
import Headers from '@/components/contents/partials/Headers'
import Bodytext from '@/components/contents/partials/Bodytext'
import Link from '@/components/contents/partials/Link'
import Video from '@/components/contents/Video'
import CovidAlert from '@/components/CovidAlert'

import Daytip from '@/components/integrations/Daytip'
import Activities from '@/components/integrations/Activities'
import Avalanches from '@/components/integrations/Avalanches'
import AllEvents from '@/components/integrations/AllEvents'
import MountainEvents from '@/components/integrations/MountainEvents'
import Experiences from '@/components/integrations/Experiences'
import Facilities from '@/components/integrations/Facilities'
import FacilitiesValley from '@/components/integrations/FacilitiesValley'
import SkitourFreeride from '@/components/integrations/SkitourFreeride'
import KidsSkiParadise from '@/components/integrations/KidsSkiParadise'
import Rentals from '@/components/integrations/Rentals'
import Restaurants from '@/components/integrations/Restaurants'
import Skitour from '@/components/integrations/Skitour'
import Skischools from '@/components/integrations/Skischools'
import Slopes from '@/components/integrations/Slopes'
import Snow from '@/components/integrations/Snow'
import Weather from '@/components/integrations/Weather'
import GuestProgram from '@/components/integrations/GuestProgram'
import Snowparks from '@/components/integrations/Snowparks'
import Livecams from '@/components/integrations/Livecams'
import PublicTransport from '@/components/integrations/PublicTransport'
import Emergencynumbers from '@/components/integrations/Emergencynumbers'
import Aed from '@/components/integrations/Aed'
import Toilets from '@/components/integrations/Toilets'
import Parking from '@/components/integrations/Parking'
import ActualSituation from '@/components/integrations/ActualSituation'
import ActualSituationSki from '@/components/integrations/ActualSituationSki'
import Infopoints from '@/components/integrations/Infopoints'
import ProfileGuestcard from '@/components/integrations/ProfileGuestcard'
import AroundMe from '@/components/integrations/AroundMe'
import Sledding from '@/components/integrations/Sledding'
import WinterHiking from '@/components/integrations/WinterHiking'
import IcePool from '@/components/integrations/IcePool'
import LiveStatus from '@/components/integrations/LiveStatus'
import BookableOffers from '@/components/integrations/BookableOffers'
import ParkingFinder from '@/components/integrations/ParkingFinder'
import CovidTestcenter from '@/components/integrations/CovidTestcenter'
import CrosscountryGastro from '@/components/integrations/CrosscountryGastro'
import CrosscountryEvents from '@/components/integrations/CrosscountryEvents'
import CrosscountryReport from '@/components/integrations/CrosscountryReport'
import ActualSituationCrosscountry from '@/components/integrations/ActualSituationCrosscountry'
import BusStation from '@/components/integrations/BusStation'
import TrainStation from '@/components/integrations/TrainStation'
import Mountaintip from '@/components/integrations/Mountaintip'
import Tipps from '@/components/integrations/Tipps'
import Events from '@/components/integrations/Events'
import Addresses from '@/components/integrations/Addresses'
import SocialMedia from '@/components/integrations/SocialMedia'
import ActualSituationBike from '@/components/integrations/ActualSituationBike'
import OaSnowSituation from '@/components/integrations/OaSnowSituation'
import OaIntegrationEmbed from '@/components/integrations/OaIntegrationEmbed'
import OaIntegrationBike from '@/components/integrations/OaIntegrationBike'
import TrailForkList from '@/components/integrations/TrailForkList'
import TrailForkMap from '@/components/integrations/TrailForkMap'
import LiveStatusSummer from '@/components/integrations/LiveStatusSummer'
import OutdoorFacilities from '@/components/integrations/OutdoorFacilities'
import Pool from '@/components/integrations/Pool'
import ParkingTeaser from '@/components/integrations/ParkingTeaser'

export default {
    name: 'Contents',
    components: {
        Html,
        Image,
        Table,
        Text,
        Textpic,
        Textpicbox,
        Textpicslider,
        Calltoaction,
        Menu_pages,
        Menu_subpages,
        Space,
        Div,
        Video,
        CovidAlert,

        Headers,
        Bodytext,
        Link,
        Daytip,

        Activities,
        Avalanches,
        AllEvents,
        MountainEvents,
        Experiences,
        Facilities,
        FacilitiesValley,
        SkitourFreeride,
        KidsSkiParadise,
        Rentals,
        Restaurants,
        CrosscountryGastro,
        Skitour,
        Skischools,
        Slopes,
        Snow,
        Weather,
        GuestProgram,
        Snowparks,
        Livecams,
        PublicTransport,
        Toilets,
        Parking,
        Emergencynumbers,
        Aed,
        ActualSituation,
        ActualSituationSki,
        Infopoints,
        ProfileGuestcard,
        AroundMe,
        Sledding,
        WinterHiking,
        IcePool,
        LiveStatus,
        BookableOffers,
        ParkingFinder,
        CovidTestcenter,
        CrosscountryEvents,
        CrosscountryReport,
        ActualSituationCrosscountry,
        BusStation,
        TrainStation,
        Mountaintip,
        Tipps,
        Events,
        Addresses,
        SocialMedia,
        ActualSituationBike,
        OaSnowSituation,
        OaIntegrationBike,
        OaIntegrationEmbed,
        TrailForkList,
        TrailForkMap,
        LiveStatusSummer,
        OutdoorFacilities,
        Pool,
        ParkingTeaser
    },
    props: {
      page: Array,
      contents: Array,
      nested_contents: Array,
    }
}
</script>

<style>
.hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.hide-scroll-bar::-webkit-scrollbar {
    display: none;
}
</style>
