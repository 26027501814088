<template>

  <div class="sw_intro" v-if="!isClicked">

    <div class="fixed z-50 inset-0 overflow-y-auto">
      <div class="flex justify-center min-h-screen h-full w-full text-center">
        <div class="fixed inset-0 bg-sw-blue-dark transition-opacity"></div>

        <span class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

        <div class="w-full h-full text-left transform transition-all pb-4 sm:align-middle">
          <div class="absolute top-0 right-0 pt-4 xs:pt-8 pr-4 z-10">
            <button v-on:click="closeModal" type="button" class="text-white hover:opacity-50 focus:outline-none">
              <span class="sr-only">Close</span>
              <svg class="h-6 w-6">
                <use xlink:href="#icon--x"></use>
              </svg>
            </button>
          </div>
          <div class="relative pb-10 h-full w-full text-white text-center">
            <div class="h-full w-full pt-8 pb-4 px-4 xs:px-8 overflow-y-scroll">
              <div class="max-w-3xl mx-auto sm:py-8 flex flex-col items-center">
                <img class="w-40 h-40 sm:w-56 sm:h-56 mb-5" :src="page1_image">
                <div v-html="page1_content"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import axios from "axios";

export default {
  name: 'Intro',
  data() {
    return {
      isClicked: false,
      page1_image: null,
      page1_content: null
    }
  },
  methods: {
    closeModal: function() {
      this.isClicked = !this.isClicked;
      this.isClicked ? document.body.classList.remove("overflow-hidden") : document.body.classList.add("overflow-hidden");
      localStorage.setItem('introClosed', true);
    },
  },
  components: {
  },
  mounted() {
    let contentUrl = process.env.VUE_APP_API_URL + "wef/spashscreen"

    if (this.$i18n.locale === "en") {
      contentUrl = process.env.VUE_APP_API_URL + "en/wef/spashscreen"
    }

    axios.get(contentUrl).then(response => {
      this.page1_image = response.data.content.colPos0[0].content.gallery.rows['1'].columns['1'].publicUrl
      this.page1_content = response.data.content.colPos0[0].content.bodytext
    })
  }
}
</script>

<style>
#c-parking-7C3CB9E2-A5BD-9544-9A21-15C9A3EB2B49,
#c-parking-26F8A890-0D7F-DB44-97EA-4D7AE7C832EA{
  display: none;
}
</style>
