<template>
  <div>
    <div v-for="parkinglot in parkinglots" v-bind:key="parkinglot.id" :id="'c-parking-'+parkinglot.id" class="mt-4 bg-sw-gray-light px-4 py-2 rounded-2xl text-left flex items-center">
      <div class="w-12 h-12 mr-6 text-sw-blue">
        <div class="sw_percentage-circle text-">
          <div class="sw_percentage-circle-text">
            <svg class="h-5 w-5 text-sw-gray-dark">
              <use xlink:href="#icon--parking"></use>
            </svg>
          </div>
          <div :class="`pie p${parkinglot.occupancy.occupied_percent}`"></div>
        </div>
      </div>
      <a :href="`/wef/parking#${parkinglot.id}`" :class="`c-parking-${parkinglot.id}`">
        <h6 class="font-sans-extrabold">{{ parkinglot.name }}</h6>
        <div class="flex items-center">
          <div :class="`mr-2 rounded-full w-4 h-4 bg-${getColorBasedOnFreeParkingLots(parkinglot.occupancy.message)}`"></div>
          <p :class="`m-0 text-${getColorBasedOnFreeParkingLots(parkinglot.occupancy.message)}`">
            <strong>{{parkinglot.occupancy.free_places}}</strong>
            {{ $t("parking.free_parking") }}
          </p>
        </div>
      </a>
      <a :href="parkinglot.dirLink" target="_blank" class="flex items-center text-sw-blue ml-auto">
        <svg class="h-7 w-7 m-auto mb-2"><use xlink:href="#icon--directions"></use></svg>
        <span>{{ parkinglot.dist.text }}</span>
      </a>
    </div>

    <div class="flex items-center justify-end mt-4">
      <a class="flex items-center text-sw-blue font-sans-extrabold text-xl" :href="appearance.detail_link">
        {{ appearance.detail_link_text }}

        <svg class="h-8 w-8">
          <use xlink:href="#icon--arrow-right"></use>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import Mixins from "@/Mixins";

export default {
  name: "ParkingTeaser",
  mixins: [Mixins],
  data() {
    return {
      parkinglots: [],
      sort_by_dist: false
    }
  },
  props: {
    appearance: Object,
    detaillink: String
  },
  methods: {
    getColorBasedOnFreeParkingLots: function(message) {

      if (message === "warning") {
        return "sw-orange"
      }

      if (message === "free") {
        return "sw-green"
      }

      if (message === "full") {
        return "sw-red"
      }

      return "sw-red"
    }
  },
  mounted() {
    axios.get(process.env.VUE_APP_API_URL+`typo3temp/feed_dk/parkingteaser_${this.$i18n.locale}.json`).then(response => {
      this.parkinglots = response.data

      this.parkinglots.forEach(parkinglot => {
        parkinglot.dist = Mixins.methods.distanceInKm(parkinglot.geoInfo.latitude, parkinglot.geoInfo.longitude, this.$t("general.plan_route"))
        if(parkinglot.dist.dist !== null){
          this.sort_by_dist = true;
        }
      });
    })
  }
}
</script>
