<template>
    <div :class="{ 'navbar--hidden': !showNavbar }">
        <TheHomescreenPopup />
        <Svg />
        <TheNavigation
            v-if="navigation"
            :servicenavigation="navigation['service']"
            :navdata="navigation['navigation']"
            :languages="languages"
            :isEventDetail="isEventDetail"
        />
        <TheServiceNavigation :navigation="navigation && navigation['dockWef']" />
        <div v-if="this.introClosed == false">
            <Intro />
        </div>

        <div class="contents">
            <!--   TODO: if Route is defined (detail etc.), hide component "Contents"!   -->
            <router-view @onEventDetailMounted="setIsEventDetail()"></router-view>

            <Contents :page="page" :contents="contents" :nested_contents="nested_contents" @hideFooter="hideFooter" disableFooter v-if="$route.name != 'eventDetail' && $route.name != 'restaurantDetail' && $route.name != 'addressDetail'" />
        </div>

        <TheFooter :navigation="navigation['serviceWef']" :social-media="navigation['social']" v-if="navigation && showFooter" />
        <TheCookieBanner />
        <div class="hidden">
            <TheLocationBanner />
        </div>
    </div>
</template>

<style lang="scss">
@import './styles/app.scss';
</style>

<script>
import axios from 'axios'
import Svg from '@/components/Svg.vue'
import TheNavigation from '@/components/TheNavigation.vue'
import TheServiceNavigation from '@/components/TheServiceNavigation.vue'
import TheFooter from '@/components/TheFooter.vue'
import Intro from '@/components/Intro.vue'
import Contents from '@/components/Contents.vue'
import TheCookieBanner from '@/components/TheCookieBanner.vue'
import TheHomescreenPopup from '@/components/TheHomescreenPopup.vue'
import TheLocationBanner from '@/components/TheLocationBanner.vue'
import Mixins from '@/Mixins'

export default {
    name: 'App',
    mixins: [Mixins],
    data() {
        return {
            introClosed: true,
            showNavbar: true,
            lastScrollPosition: 0,
            showFooter: true,
            isEventDetail: false,
            navigation: null,
            languages: null,
            page: null,
            contents: null,
            nested_contents: null,
        }
    },
    methods: {
        setIsEventDetail() {
            this.isEventDetail = true
        },
        hideFooter() {
            this.showFooter = false
        },
        onScroll() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
            if (currentScrollPosition < 0) {
                return
            }
            // Stop executing this function if the difference between
            // current scroll position and last scroll position is less than some offset
            if (
                Math.abs(currentScrollPosition - this.lastScrollPosition) < 60 &&
                document.body.scrollHeight - window.outerHeight - currentScrollPosition > 0
            ) {
                return
            }

            this.showNavbar =
                currentScrollPosition <= this.lastScrollPosition || document.body.scrollHeight - window.outerHeight - currentScrollPosition < 0
            this.lastScrollPosition = currentScrollPosition
        },
    },
    mounted() {
        Mixins.methods.watchUserPosition()
        Mixins.methods.checkForExpiredGuestcards()

        if (localStorage.getItem('introClosed')) {
            this.introClosed = localStorage.getItem('introClosed')
        } else {
            this.introClosed = false
        }
        window.addEventListener('scroll', this.onScroll)

        let navSourceUrl = process.env.VUE_APP_API_URL

        if (this.$i18n.locale === 'en') {
            navSourceUrl += "en/"
        }

        const buddy = this.getBuddy()

        if (buddy) {
            navSourceUrl += buddy.buddy
        }

        navSourceUrl += "?type=834"

        axios.get(navSourceUrl).then((response) => {
            this.navigation = response.data
        })


      axios.get(process.env.VUE_APP_API_URL + window.location.pathname).then((response) => {
        var nested_contents = [],
            key = 0
        this.languages = response.data['languages']
        this.page = response.data['page']
        document.title = this.page.meta.title || 'wef.davos.ch'

        // hide footer if necessary
        if (this.page.appearance.layout === 'layout-1' || this.page.integration !== "") {
          this.hideFooter();
          this.$emit('hideFooter')
        }

        this.contents = response.data['content']['colPos0']
        this.nested_contents = response.data['content']['colPos999']
            ? response.data['content']['colPos999'].forEach(function (content) {
              key = content['content']['parent']
              if (!nested_contents[key]) {
                nested_contents[key] = []
              }
              nested_contents[key].push(content)
            })
            : null
        this.nested_contents = nested_contents
      })
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    components: {
        Svg,
        TheNavigation,
        TheServiceNavigation,
        TheFooter,
        Intro,
        Contents,
        TheCookieBanner,
        TheLocationBanner,
        TheHomescreenPopup,
    },
}
</script>
