import { createWebHistory, createRouter } from 'vue-router';
import EventDetail from '../components/integrations/detail/Event.vue'
import RestaurantDetail from '../components/integrations/detail/Restaurant.vue'
import AddressDetail from "@/components/integrations/detail/Address.vue"

const routes = [
    {
        path: '/events/:slug',
        name: 'eventDetail',
        component: EventDetail,
        props: true
    },
    {
        path: '/restaurants-und-skihuetten/:slug',
        name: 'restaurantDetail',
        component: RestaurantDetail,
        props: true
    },
    {
        path: '/addressen/:slug',
        name: 'addressDetail',
        component: AddressDetail,
        props: true
    },
    /*{
        path: "/:catchAll(.*)",
        component: NotFound,
    },*/
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
