<template>
  <section v-if="!closedByUser && header" class="mx-auto max-w-7xl sm:px-2 lg:px-16">
    <div class="text-sw-white py-2 px-4 relative bg-red-500">
      <svg @click="closeAlert()" class="flex-shrink-0 h-6 w-6 absolute right-2">
        <use xlink:href="  #icon--x"></use>
      </svg>
      <h2 v-if="header" class="font-sans-extrabold">{{header}}</h2>
      <div class="alert-text" v-html="content"></div>
      <a v-if="link" :href="link" target="_blank" class="flex">
        → {{ $t('labels.learn-more') }}
      </a>
    </div>
  </section>

</template>

<script>
import axios from "axios";

export default {
  name: 'CovidAlert',
  data() {
    return {
      header: null,
      content: null,
      link: null,
      closedByUser: false,
    }
  },
  methods: {
    closeAlert: function() {
      localStorage.setItem("alertClosed", 1)
      this.closedByUser = true
    }
  },
  mounted() {
    let contentUrl = process.env.VUE_APP_API_URL +"wef/alert"

    if (this.$i18n.locale === "en") {
      contentUrl = process.env.VUE_APP_API_URL + "en/wef/alert"
    }

    axios.get(contentUrl).then(response => {
      if(response.data.content.colPos0){
        this.header = response.data.content.colPos0[0].content.header
        this.content = response.data.content.colPos0[0].content.bodytext
        this.link = response.data.content.colPos0[0].content.headerLink.url
      }
    })

    if (localStorage.getItem("alertClosed")) {
      this.closedByUser = true
    }
  }
}
</script>
